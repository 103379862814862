import classNames from 'classnames';
import * as React from 'react';
import BuildYourProduct from '../icons/BuildYourProduct';
import Text from '../Text';
import './style.scss';

const GraphBuildYourProduct: React.SFC = () => {
  return (
    <div className={'graph'}>
      <BuildYourProduct />
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-small',
          'graph__container--width-588-small',
          'graph__container--white',
          'graph__container--top-518-76',
          'graph__container--left-588-214'
        )}
      >
        <Text
          type="p"
          color="alternative2"
          weight="medium"
          className={classNames('h6', 'text')}
        >
          Research
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-small',
          'graph__container--width-588-small',
          'graph__container--primary',
          'graph__container--top-518-236',
          'graph__container--left-588-53'
        )}
      >
        <Text
          type="p"
          color="light"
          weight="medium"
          className={classNames('h6', 'text')}
        >
          Analysis
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-small',
          'graph__container--width-588-small',
          'graph__container--primary',
          'graph__container--bottom-518-80',
          'graph__container--left-588-214'
        )}
      >
        <Text
          type="p"
          color="light"
          weight="medium"
          className={classNames('h6', 'text')}
        >
          Delivery
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-small',
          'graph__container--width-588-small',
          'graph__container--secondary',
          'graph__container--top-518-236',
          'graph__container--right-588-53'
        )}
      >
        <Text
          type="p"
          color="light"
          weight="medium"
          className={classNames('h6', 'text')}
        >
          Prototyping
        </Text>
      </div>
    </div>
  );
};

export default GraphBuildYourProduct;
