import * as React from 'react';

const BuildYourProduct = () => (
  <svg width={558} height={518} viewBox="0 0 558 518">
    <g fill="none" fillRule="evenodd">
      <circle
        stroke="#CFD3DE"
        strokeWidth={2}
        strokeLinecap="round"
        cx={279}
        cy={259}
        r={160}
      />
      <path
        d="M395.5 260C320.113 260 259 321.113 259 396.5M376.03 249.24c-53.306-53.307-139.733-53.307-193.04 0"
        stroke="#CFD3DE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray={6}
      />
      <circle fill="#CFD3DE" cx={274} cy={209} r={6} />
      <circle fill="#CFD3DE" cx={265} cy={356} r={6} />
      <path
        d="M118.5 40h111c27.614 0 50 22.386 50 50v8.5h0"
        stroke="#CFD3DE"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle
        stroke="#CFD3DE"
        strokeWidth={2}
        fill="#FFF"
        strokeLinecap="round"
        cx={119}
        cy={40}
        r={6}
      />
      <rect
        fill="#CFD3DE"
        transform="rotate(-45 404 159)"
        x={395}
        y={150}
        width={18}
        height={18}
        rx={4}
      />
      <rect
        fill="#CFD3DE"
        transform="rotate(-45 158 363)"
        x={149}
        y={354}
        width={18}
        height={18}
        rx={4}
      />
    </g>
  </svg>
);

export default BuildYourProduct;
