import * as React from 'react';
import './style.scss';
import classNames from 'classnames';
import Text from '../Text';
import Button from '../Button';

interface Props {
  backgroundColor: 'primary' | 'secondary';
  reversed?: boolean;
  title: string;
  subtitle: string;
  description: string;
  button: {
    text: string;
    href: string;
  };
  secondButton?: {
    text: string;
    href?: string;
  };
  image?: React.SFC;
}

const ServiceDetailed: React.SFC<Props> = ({
  backgroundColor,
  reversed = false,
  title,
  subtitle,
  description,
  button,
  secondButton,
  image,
}) => {
  const Image = image;

  return (
    <div
      className={classNames(
        'service-detailed',
        {
          ['service-detailed--reversed']: reversed,
        },
        `service-detailed--background-${backgroundColor}`
      )}
    >
      <div className="service-detailed__wrap">
        <div
          className={classNames('service-detailed__content', {
            ['service-detailed__content--reversed']: reversed,
          })}
        >
          <Text
            type="h2"
            color="dark"
            weight="demi-bold"
            className={'service-detailed__content__title h3'}
          >
            {title}
          </Text>
          <Text
            type="p"
            color="dark"
            weight="medium"
            className={'service-detailed__content__subtitle h4'}
          >
            {subtitle}
          </Text>
          <Text
            type="p"
            color="dark"
            weight="medium"
            className={'service-detailed__content__description h5'}
          >
            {description}
          </Text>
          <Button
            href={button.href}
            outlined
            color="transparent"
            size="regular"
            className={'service-detailed__content__button'}
          >
            {button.text}
          </Button>
          {secondButton ? (
            <Button
              href={secondButton.href}
              color="light"
              size="big"
              rounded
              className={
                secondButton ? 'service-detailed__content__button--second' : ''
              }
            >
              {secondButton.text}
            </Button>
          ) : null}
        </div>
        {image ? (
          <div className={'service-detailed__graph'}>
            <Image />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ServiceDetailed;
