import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedBackground from '../AnimatedBackground';
import Text from '../Text';
import TrustedBy from '../TrustedBy';
import './style.scss';

const WelcomePageHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={'welcome-page-header'}>
      <AnimatedBackground />
      <div className={'homepage-header-content'}>
        <Text
          type="h1"
          color="light"
          weight="bold"
          className={
            'homepage-header-content__text homepage-header-content__heading'
          }
        >
          {t('homepage_heading')}
        </Text>
        <br />

        <div className="homepage-subheader">
          <div className="ukrainian-flag">
            <div className="ukrainian-flag__upper" />
            <div className="ukrainian-flag__lower" />
          </div>
          <Text
            type="label"
            color="light"
            weight="demi-bold"
            className={
              'homepage-header-content__text homepage-header-content__subheading'
            }
          >
            {t('homepage_subheading')}
          </Text>
        </div>
      </div>

      <TrustedBy lightCard />
    </div>
  );
};

export default WelcomePageHeader;
