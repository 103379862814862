import * as React from 'react';
import './style.scss';

const AnimatedBackground: React.SFC = () => {
  // const addIcoJS = React.useCallback(() => {
  //   const scriptIcoJS = document.createElement('script');
  //   scriptIcoJS.src = '/js/ico_js.js';
  //   scriptIcoJS.type = 'text/javascript';
  //   scriptIcoJS.async = true;

  //   document.body.appendChild(scriptIcoJS);
  // }, []);

  // React.useEffect(() => {
  //   const scriptIco = document.createElement('script');
  //   scriptIco.src = '/js/ico.js';
  //   scriptIco.type = 'text/javascript';
  //   scriptIco.async = true;

  //   document.body.appendChild(scriptIco);

  //   window.addEventListener('load', addIcoJS);

  //   return () => {
  //     window.removeEventListener('load', addIcoJS);
  //     document.body.removeChild(scriptIco);
  //   };
  // }, []);

  return (
    <div id="animated-background-wrapper">
      <div id="container"></div>
    </div>
  );
};

export default AnimatedBackground;
