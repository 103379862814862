import classNames from 'classnames';
import * as React from 'react';
import ExtendTeam from '../icons/ExtendTeam';
import Text from '../Text';
import './style.scss';

const GraphExtendTeam: React.SFC = () => {
  return (
    <div className={'graph'}>
      <ExtendTeam />
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-regular',
          'graph__container--white',
          'graph__container--top-518-64',
          'graph__container--left-588-123'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="medium"
          className={'text h6'}
          align="center"
        >
          Client C-Level
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-regular',
          'graph__container--secondary',
          'graph__container--top-518-64',
          'graph__container--right-588-123'
        )}
      >
        <Text type="label" color="light" weight="medium" className={'text h6'}>
          SM EM
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-medium',
          'graph__container--white',
          'graph__container--top-518-224',
          'graph__container--left-588-201'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="medium"
          className={'text h6'}
        >
          Client PM
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-regular',
          'graph__container--primary',
          'graph__container--bottom-518-64',
          'graph__container--left-588-35'
        )}
      >
        <Text type="label" color="light" weight="medium" className={'text h6'}>
          SM Dev
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-regular',
          'graph__container--primary',
          'graph__container--bottom-518-64',
          'graph__container--left-588-211'
        )}
      >
        <Text type="label" color="light" weight="medium" className={'text h6'}>
          SM Dev
        </Text>
      </div>
      <div
        className={classNames(
          'graph__container',
          'graph__container--height-518-big',
          'graph__container--width-588-wide',
          'graph__container--white',
          'graph__container--bottom-518-64',
          'graph__container--right-588-20'
        )}
      >
        <Text
          type="label"
          color="alternative2"
          weight="medium"
          className={'text h6'}
        >
          Client Dev
        </Text>
      </div>
    </div>
  );
};

export default GraphExtendTeam;
