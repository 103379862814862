import { graphql } from 'gatsby';
import React from 'react';
import BuiltByStormotion from '../components/BuiltByStormotion';
import Comments from '../components/Comments';
import Footer from '../components/Footer';
import GraphBuildYourProduct from '../components/Graphs/GraphBuildYourProduct';
import GraphExtendTeam from '../components/Graphs/GraphExtendTeam';
import Header from '../components/Header';
import Ownership from '../components/icons/Ownership';
import PartnerInCrime from '../components/icons/PartnerInCrime';
import WellEstablishedProcess from '../components/icons/WellEstablishedProcess';
import LatestArticlesList from '../components/LatestArticlesList';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import SEO from '../components/seo';
import ServiceDetailed from '../components/ServiceDetailed';
import ServicesAndTechnologies from '../components/ServicesAndTechnologies';
import Special, { Trait } from '../components/Special';
import WelcomePageHeader from '../components/WelcomePageHeader';
import WeWorkWith from '../components/WeWorkWith';
import useApps from '../hooks/useApps';

const traits: Trait[] = [
  {
    title: 'Your Partner in Crime',
    icon: PartnerInCrime,
    description:
      'Whether startup or SME. We speak the same language and empathize with you to solve your business and tech-challenges.',
  },
  {
    title: 'Well-Established Process',
    icon: WellEstablishedProcess,
    description: `Developing a Digital Product is hard. We know the right execution formula and will share our process and experience with you.`,
  },
  {
    title: 'Ownership',
    icon: Ownership,
    description:
      'No blind following of your instructions. We co-create the product together with you and think outside the box to meet your goals.',
  },
];

const IndexPage = () => {
  const apps = useApps(true);

  return (
    <Layout>
      <Navigation headerBackgroundColor="none" />
      <Header>
        <WelcomePageHeader />
      </Header>
      <BuiltByStormotion apps={apps} />
      <ServiceDetailed
        backgroundColor="primary"
        title="Build your Product"
        subtitle="Have a product idea and nobody to start building it? We’re your team."
        description="With our experience we'll co-create and deliver a Web or Mobile product with you. Regardless if you only have an idea or ready UI designs — we'll manage the entire product development life cycle."
        button={{
          text: 'Our Process',
          href: '/digital-product-development-services/',
        }}
        secondButton={{
          text: '$28 000 000 invested in companies we’ve worked with',
          href: '/digital-product-development-services/',
        }}
        image={GraphBuildYourProduct}
      />
      <ServiceDetailed
        backgroundColor="secondary"
        reversed
        title="Extend and Speed up your Dev-Process"
        subtitle="Need to scale up your team or look for a specific expertise?"
        description="Whether React.JS or QA - a dedicated remote team of experienced rockstars will extend your team with the relevant expertise for your project. From one Developer to complete Scrum teams."
        button={{
          text: 'Our Process',
          href: '/it-staff-augmentation-services/',
        }}
        image={GraphExtendTeam}
      />
      <ServicesAndTechnologies />
      <Special traits={traits} />
      <WeWorkWith />
      <Comments title="Our Clients Trust Us" />
      <LatestArticlesList />
      <Footer noFooterCard={false} />
    </Layout>
  );
};

export const query = graphql`
  query Homepage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Mobile and Web Development Company | Stormotion"
    description="Hire Stormotion to build your next highly scalable custom web or mobile application with JavaScript. We’re recognized as a top mobile application development company and are experts in React, React Native, Node.js."
  />
);

export default React.memo(IndexPage);
