import * as React from 'react';

const ExtendTeam = () => (
  <svg width={358} height={302} viewBox="0 0 358 302" fill="none">
    <path
      d="M197.5 15.5h-40M264.75.5V54c0 23.196-18.804 42-42 42H219c-23.196 0-42 18.804-42 42v24"
      stroke="#CFD3DE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeDasharray={6}
    />
    <rect
      x={210.272}
      y={95}
      width={18}
      height={18}
      rx={4}
      transform="rotate(-45 210.272 95)"
      fill="#CFD3DE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 182a6 6 0 100-12 6 6 0 000 12zM177 265a6 6 0 100-12 6 6 0 000 12zM352 236a6 6 0 100-12 6 6 0 000 12z"
      fill="#CFD3DE"
    />
    <path
      d="M177 189v112M177 176H25.5c-13.255 0-24 10.745-24 24v101M177 176h151.5c13.255 0 24 10.745 24 24v101"
      stroke="#CFD3DE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeDasharray={6}
    />
  </svg>
);

export default ExtendTeam;
