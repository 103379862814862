import BackgroundImage from 'gatsby-background-image';
import React, { useCallback, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import useWeWorkWithApps, {
  WeWorkWithApp,
} from '../../hooks/useWeWorkWithApps';
import Button from '../Button';
import Text from '../Text';
import './style.scss';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

const sliderSettings: Settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 12000,
  className: 'built-by-stormotion__content__slider',
  infinite: true,
  pauseOnHover: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 1,
  lazyLoad: 'ondemand',
};

const WeWorkWith: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const apps = useWeWorkWithApps();

  const beforeChange = useCallback<
    (prevSlide: number, nextSlide: number) => void
  >((_, nextSlide) => {
    setActiveSlide(nextSlide);
  }, []);

  const appsCount = apps.length;

  const renderApp = useCallback<(app: WeWorkWithApp) => React.ReactNode>(
    (app) => (
      <BackgroundImage
        Tag="div"
        className="header__hero__image__bg__wrapper"
        {...convertToBgImage(getImage(app.image.childImageSharp))}
      >
        <div className="work-with__slide work-with__content appSlide">
          <div className="appSlide__wrapper">
            <Text
              type="h3"
              color="light"
              weight="bold"
              className="appSlide__title"
            >
              {app.title}
            </Text>
            <Text type="p" color="light" className="appSlide__description h5">
              {app.description}
            </Text>
            {app.link && (
              <Button
                href={app.link}
                outerLink
                size="regular"
                color="light"
                darkMode
                outlined
              >
                Case Study
              </Button>
            )}
          </div>
        </div>
      </BackgroundImage>
    ),
    []
  );

  return (
    <div className="work-with">
      <div className="work-with__content work-with__overlay overlay">
        <div className="overlay__wrapper">
          <Text
            type="h2"
            color="light"
            weight="bold"
            className="overlay__title"
          >
            We often work with
          </Text>
          <div
            className="overlay__progress"
            style={{
              width: `calc(100% / ${appsCount})`,
              left: `calc(${activeSlide * (100 / appsCount)}%)`,
            }}
          />
        </div>
      </div>
      <Slider
        {...sliderSettings}
        beforeChange={beforeChange}
        className="work-with__slider"
      >
        {apps?.map(renderApp)}
      </Slider>
    </div>
  );
};

export default WeWorkWith;
