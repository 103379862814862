import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useMemo } from 'react';
import useServices from '../../hooks/useServices';
import CardBoard from '../CardBoard';
import Firebase from '../icons/Firebase';
import GraphQL from '../icons/GraphQL';
import Kotline from '../icons/Kotline';
import NodeJs from '../icons/NodeJs';
import ReactIcon from '../icons/React';
import Swift from '../icons/Swift';
import TypeScript from '../icons/TypeScript';
import ListOfIcons from '../ListOfIcons';
import './style.scss';

interface Props {}

const services = [
  {
    title: 'Mobile Development',
    description:
      'Our key competence is building Apps with a user-centered native UX. React Native is the framework, which allows us to make this magic happen on both iOS & Android.',
    href: '/mobile-application-development-services/',
  },
  {
    title: 'Web Development',
    description:
      'We develop scalable Web Applications with well-tested React front-End, which allows our clients to reach customers & users across all platforms.',
    href: '/web-application-development-services/',
  },
  {
    title: 'Startup',
    description:
      "PoC for the next Investor Pitch? Fixed-Price MVP for validating Product-Market Fit? Whatever stage you're at - we're on the same wavelength to solve your challenges.",
  },
  {
    title: 'Digital Product for SME',
    description:
      'Need to create a Mobile or Web App for digitizing & automating business processes? We know how to build a scalable and well-tested Product for your business.',
    href: '/digital-product-development-services/',
  },
];

const ServicesAndTechnologies: React.SFC<Props> = () => {
  const icons = useMemo(
    () => [
      <Firebase />,
      <NodeJs />,
      <Kotline />,
      <ReactIcon />,
      <Swift />,
      <TypeScript />,
      <GraphQL />,
    ],
    []
  );

  const data = useServices();

  const servicesWithImages = useMemo(
    () =>
      [data.mobile, data.web, data.startup, data.digital].map(
        (icon, index) => ({
          image: <GatsbyImage image={icon!} alt={services[index].title} />,
          ...services[index],
        })
      ),
    [data]
  );

  return (
    <div className={'services-and-technologies'}>
      <CardBoard
        title="Yes, we can help you with that."
        data={servicesWithImages}
      />
      <ListOfIcons icons={icons} title="Driven by amazing Technologies" />
    </div>
  );
};

export default ServicesAndTechnologies;
