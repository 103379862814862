import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { values } from 'ramda';

const appsBase: AppBase[] = [
  {
    title: 'HealthCare, Fitness & Sport',
    description:
      'Together with our clients, we deliver top Mobile and Web Apps for the Fitness industry. Our expertise ranges from building on-demand Fitness Streaming products to classical Nutrition & Workout Apps. We can also help you with third-party Fitness Integrations: whether it’s BLE-based wearables like heart-rate monitors, cadence sensors or services like Google Fit and Apple Health.',
    link: 'https://stormotion.io/fitness-app-development-services/',
  },
  {
    title: 'Meditation & Mindfulness Products',
    description:
      'We change the landscape of Meditation & Mindfulness Products by co-creating the Mobile and Web Apps of our clients. From On-demand Audio/Video streaming of Yoga classes to Subscription management of Meditation Products - we have everything covered. Our expertise also includes Coaching App development for both offline events and online learning.',
    link: 'https://stormotion.io/blog/how-to-make-a-meditation-app-like-headspace/',
  },
  {
    title: 'E-Commerce Products',
    description:
      'We have sufficient expertise in customizing, upgrading, and maintaining small and large-scale e-commerce platforms. Our rockstars will help to build your e-commerce website from scratch, add an advanced filter & search, build an On-Demand Delivery & Booking Platform or just migrate your old data to a new framework.',
  },
  {
    title: 'TravelTech Products',
    description:
      'From hotel booking via a Chatbot or API to Itinerary Management, Currency Converters and Flight alerts - we know what it takes to build a great Travel Web & Mobile Product.',
  },
  {
    title: 'Membership & Content-Based Products',
    description:
      'We create Subscription and Content-Based Applications for Businesses and Startups of all sizes. Whether prepaid fitness classes or a custom copywriting trainer, we pick the Tech-Stack that matches your business needs. Streaming, Map integration and offline access to the content are just some sides of our expertise.',
    link: 'https://stormotion.io/membership-website-development-services/',
  },
];

interface ImageData {
  childImageSharp: IGatsbyImageData;
}

interface AppBase {
  title: string;
  description: string;
  link?: string;
}

interface App extends AppBase {
  image: ImageData;
}

export type WeWorkWithApp = App;

interface WorkWithImagesData {
  [key: string]: ImageData;
}

const useWeWorkWithApps = (): WeWorkWithApp[] => {
  const imagesData = useStaticQuery<WorkWithImagesData>(graphql`
    {
      workWith1: file(relativePath: { eq: "work-with-1.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      workWith2: file(relativePath: { eq: "work-with-2.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      workWith3: file(relativePath: { eq: "work-with-3.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      workWith4: file(relativePath: { eq: "work-with-4.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      workWith5: file(relativePath: { eq: "work-with-5.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  const images = values(imagesData);

  const apps: WeWorkWithApp[] = appsBase.map((item, i) => ({
    ...item,
    image: images[i],
  }));

  return apps;
};

export default useWeWorkWithApps;
